import React, { useState } from "react";
import logo from "assets/icons/logo-new-white-orange.png";
import styles from "./header.module.css";
import { Button } from "elements/buttons";
import config from ".config.js";
import generalStyle from "app.module.css";
import { Icon } from "@iconify/react";

export const Header = () => {
  const [activeState, setActiveState] = useState(false);

  const handleLoginAction = () => {
    window.location = `${config.app_url}/login`;
  };

  const handleSignUpAction = () => {
    window.location = `${config.app_url}/register`;
  };

  const toggleNavButton = () => {
    setActiveState((previousState) => !previousState);
  };

  const NavLinks = () => (
    <div className={styles.navLinks}>
      <a href="https://go-mailer.blog" onClick={() => setActiveState(false)}>
        {" "}
        Blog{" "}
      </a>
      <a href="#features" onClick={() => setActiveState(false)}>
        Features{" "}
      </a>
      <a href="#pricing" onClick={() => setActiveState(false)}>
        {" "}
        Pricing{" "}
      </a>
    </div>
  );

  const NavButtons = () => (
    <div className={styles.actions}>
      <Button text="Log In" className={styles.loginBtn} onClick={handleLoginAction} />
      <Button text="Sign Up" className={styles.signUpBtn} onClick={handleSignUpAction} />
    </div>
  );

  return (
    <header className={styles.header}>
      <div className={generalStyle.gmContainer}>
        <div className={styles.navWrapper}>
          <div className={styles.siteInfo}>
            <a href="*" className={styles.siteLink}>
              <img src={logo} alt="Go-Mailer Icon" className={styles.siteIcon} />
            </a>
            <nav className={styles.navBar}>
              <NavLinks />
            </nav>
          </div>

          <div className={styles.headBtn}>
            <NavButtons />
          </div>
          <div className={styles.options}>
            <Icon icon={`lucide:${activeState ? "x" : "menu"}`} onClick={toggleNavButton} />
          </div>
        </div>
        <div className={`${styles.mobileNav} ${activeState ? styles.showNavbar : ""}`}>
          <header>
            <img src={logo} alt="Go-Mailer Icon" className={styles.siteIcon} />
          </header>
          <div className={styles.mobileNavWrapper}>
            <NavLinks />
            <NavButtons />
          </div>
        </div>
      </div>
    </header>
  );
};
