import React, { useState } from "react";
import cx from "classnames";
import GoMailerWebPush from "@go-mailer/web-push";
import styles from "./main.module.css";
import generalStyle from "app.module.css";
import { EmailInput } from "elements/inputs";
import landing_image from "assets/images/landing-image.png";
import * as userService from "services/user";
import config from ".config";

export const Main = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = async () => {
    const subscription_token = await GoMailerWebPush.requestPermission();
    if (subscription_token) {
      await GoMailerWebPush.saveSubscriptionToken(subscription_token, { email });
    }

    const {
      data: { error },
    } = await userService.createContact(`email=${email}&tags=pre-registration`);
    if (error) {
      return;
    }
    window.location.href = `${config.app_url}/register?email=${encodeURIComponent(email)}`;
  };

  return (
    <main className={styles.main}>
      <div className={cx(generalStyle.gmContainer, styles.layout)}>
        <div className={styles.mainWrapper}>
          <div className={styles.mainText}>
            <div className={styles.headingTitle}>
              <h1>
                Engage your users,
                <span className={styles.blue}>
                  {" "}
                  Grow your revenue<span className={styles.orange}>.</span>
                </span>
              </h1>
            </div>
            <div className={styles.tagline}>
              Unlock new levels of user engagement and revenue growth with the world's most flexible, performant and
              cost-efficient multi-channel marketing platform - Send emails, SMS messages, Push Notifications and more.
            </div>
          </div>
          <div className={styles.emailInputContainer}>
            <EmailInput value={email} onInput={(value) => setEmail(value)} onSubmit={handleSubmit} />
          </div>
        </div>
        <div className={styles.landingImage}>
          <img src={landing_image} alt="Landing page dashboard" />
        </div>
      </div>
    </main>
  );
};
