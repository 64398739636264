import React from "react";
import config from ".config.js";
import transactionals from "assets/svg/features/transactional.svg";
import automation from "assets/svg/features/automata.svg";
import massmailing from "assets/svg/features/mass-email.svg";
import { Feature } from "elements/feature";
import styles from "./features.module.css";
import generalStyle from "app.module.css";
import { Icon } from "assets/icons";

const features = [
  {
    icon: massmailing,
    tagline: "Mass Mailing",
    title: "Reach Every User, Every Time - with Just One Click.",
    description: `Increase your brand loyalty and conversions.
    
    Effortlessly build deeper relationships with your users and drive higher revenue with simple, relatable and personalized mass messages via emails, SMS, or Push Messages.`,
    bgColor: "#FFBF6033",
    color: "#FFBF60",
    href: "https://go-mailer.blog/newsletters/",
    cta: "Get Started",
  },
  {
    icon: transactionals,
    tagline: "TRANSACTIONAL EMAILS",
    title: "Respond to Your User Actions in Real-Time",
    description: `Enjoy timely responses to your users. 
      
    Keep your users active and engaged by delivering highly relevant and personalized emails in response to their actions on your app. From OTPs to confirmation emails and everything in between, we've got you covered.`,
    bgColor: "#2A99FF33",
    color: "#2A99FF",
    href: "https://go-mailer.blog/reliable-transactional-email-software/",
    cta: "Get Started",
    direction: "rtl",
  },
  {
    icon: automation,
    tagline: "AUTOMATION",
    title: "Save Time & Boost Conversions 10x Faster",
    description: `Focus on the interesting stuff while we handle the boring.
    
    Transform your leads into loyal users and drive higher revenues through well-crafted and perfectly-timed automated actions - send the right message, add to the right segment and much more.`,
    bgColor: "#66B10733",
    color: "#66B107",
    href: "https://go-mailer.blog/email-marketing-automation-to-boost-conversions/",
    cta: "Get Started",
  },
];

const featuresData = [
  {
    title: "Unlimited Subscribers For Unlimited Growth",
    description: `Take full control of your brand's growth with unlimited subscriber storage.
      
    You can now run your Ads and lead generation strategies without worrying about exceeding subscriber limits or expensive subscription fees.
    `,
    url: "https://go-mailer.blog/advanced-subscriber-management-system-for-high-engagement/#unlimited-subscriber-storage",
  },
  {
    title: "Advanced Segmentation For Targeted Messaging",
    description: `Increase your conversions by sending the right message to the right user segment.
    
    Advanced criteria-based segmentation enables you to split your user-base into fine-grained segments for a more targeted and relatable messaging.
    `,
    url: "https://go-mailer.blog/advanced-subscriber-management-system-for-high-engagement/#unlimited-audience-segmentation",
  },
  {
    title: "Subscriber Synchronization And Attribution",
    description: `Collect, store, and utilize all the user data you need for success.
    
    With simple and seamless APIs, you can keep your subscriber list in sync with your application data and store all the user data that your strategy needs.
    `,
    url: "https://go-mailer.blog/advanced-subscriber-management-system-for-high-engagement/#advanced-subscriber-synchronization",
  },
  {
    title: "Advanced Performance Reports For Refined Strategies",
    description: `Position your brand for greater success with data driven strategies.
    
    Gain deeper insights into your marketing activity to craft clearer and more informed strategy for improved results.
    `,
    url: "https://go-mailer.blog/advanced-performance-reporting/",
  },
  {
    title: "Flexible Payments For Adaptable Marketing",
    description: `Adapt your budget to your marketing activity.
    
    Maximize the value of your money by taking advantage of our usage-based payments or flexible subscription models that enables you to pay for only the products you use.
    
    No more. No less.
    `,
    url: "https://go-mailer.blog/about-go-mailer-pricing-options/",
  },
  {
    title: "Free Email Validations. Zero SPAM",
    description: `Maintain a high sender reputation for high open rates and conversions.
    
    Your subscriber list is continuously validated to minimize bounce rates and to ensure that your emails get delivered to your users inboxes.
    
    Higher reputation. Zero SPAM.
    `,
    url: "https://go-mailer.blog/advanced-subscriber-management-system-for-high-engagement/#free-email-validations",
  },
];

const handleSignUpAction = () => {
  window.location = `${config.app_url}/register`;
};

export const Features = () => {
  return (
    <section id="features" className={styles.features}>
      <div className={generalStyle.gmContainer}>
        <div className={styles.wrapper}>
          <div className={styles.featureList}>
            {features.map((feature, index) => (
              <Feature
                key={index}
                {...feature}
                action={
                  <a href={`${config.app_url}/register`} onClick={handleSignUpAction}>
                    {feature.action}
                  </a>
                }
              />
            ))}
          </div>
          <div className={styles.otherFeatures}>
            {featuresData.map((feature, index) => (
              <div key={index} className={styles.featureBox}>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
                <a href={feature.url}>
                  <span>Learn More </span>
                  <Icon className={styles.featureBoxIcon} name="arrow_right_flat" />
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
